<template>
  <div>
    <SubscriptionInfoCard
      v-if="investorSubscriptionInformation"
      :investorSubscriptionInformation="investorSubscriptionInformation"
      :product="product"
      :investLoading="investLoading"
      @invest="$emit('invest')"
    />
    <SubscriptionInvestmentCard
      v-if="investorSubscriptionInformation.subscribedTokens > 0"
      :investorSubscriptionInformation="investorSubscriptionInformation"
      class="mt-5"
    />
    <SubscriptionTermsCard
      class="mt-5"
      :product="product"
      :performance="performance"
      :average="average"
    />
  </div>
</template>

<script>
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'SubscriptionDetails',
  components: {
    SubscriptionInfoCard: lazyLoad('components/Marketplace/NewDeals/ProductDetails/SubscriptionInfoCard'),
    SubscriptionInvestmentCard: lazyLoad('components/Marketplace/NewDeals/ProductDetails/SubscriptionInvestmentCard'),
    SubscriptionTermsCard: lazyLoad('components/Marketplace/NewDeals/ProductDetails/SubscriptionTermsCard'),
  },
  props: {
    investorSubscriptionInformation: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    investLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    performance() {
      switch (this.product.id) {
        case 'f4cfa74f-a415-4bac-8411-251076a49948':
          return '4,58';
        default:
          return '9,98';
      }
    },
    average() {
      switch (this.product.id) {
        case 'f4cfa74f-a415-4bac-8411-251076a49948':
          return '1,98';
        default:
          return '2,84';
      }
    },
  },
};
</script>
